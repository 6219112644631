import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c104a130"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center p-2"
};
const _hoisted_2 = {
  class: "relative grow base-input rounded-t-md transition-all duration-300 ease-in-out"
};
const _hoisted_3 = ["value", "placeholder", "disabled", "readonly", "autocomplete"];
const _hoisted_4 = {
  class: "h-1.5 overflow-hidden -mt-0.5"
};
const _hoisted_5 = {
  key: 1,
  class: "absolute top-0 right-1 text-red-600 font-bold"
};
const _hoisted_6 = {
  key: 1,
  class: "flex w-12 h-12 border-l-2 self-center"
};
const _hoisted_7 = {
  key: 0,
  class: "text-red-500"
};
const _hoisted_8 = {
  key: 1,
  class: "text-red-500"
};
const _hoisted_9 = {
  key: 2,
  class: "text-tertiary-500"
};
const _hoisted_10 = {
  key: 3,
  class: "text-grey-300"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseLoading = _resolveComponent("BaseLoading");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(["flex bg-grey-100 rounded-t-md transition-all duration-300 ease-in-out", {
      ' shadow-md shadow-primary-400': $props.active
    }])
  }, [this.$slots.prepend ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "prepend", {}, undefined, true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [$props.customPlaceholder ? (_openBlock(), _createElementBlock("label", {
    key: 0,
    class: _normalizeClass(["absolute pl-5 font-semibold text-grey-500 normal transition-all duration-300 ease-in-out hasPlaceholder", {
      error: $setup.error ?? $props.errorMessage,
      warning: $props.warningMessage,
      success: $props.successMessage
    }])
  }, [_createElementVNode("span", null, _toDisplayString($props.label ?? "Base Input"), 1)], 2)) : _createCommentVNode("", true), _createElementVNode("input", _mergeProps(_ctx.$attrs, {
    onInput: _cache[0] || (_cache[0] = $event => {
      $setup.handleChange($event);
      $setup.valChange('input', $event);
    }),
    onBlur: _cache[1] || (_cache[1] = $event => {
      $setup.handleBlur($event);
      $setup.valChange('blur', $event);
    }),
    onFocus: _cache[2] || (_cache[2] = $event => $setup.valChange('focus', $event)),
    onChange: _cache[3] || (_cache[3] = $event => {
      $setup.handleChange($event);
      $setup.valChange('change', $event);
    }),
    onKeydown: _cache[4] || (_cache[4] = $event => $setup.valChange('keydown', $event)),
    onKeyup: _cache[5] || (_cache[5] = $event => $setup.valChange('keyup', $event)),
    onKeypress: _cache[6] || (_cache[6] = $event => $setup.valChange('keypress', $event)),
    value: $props.modelValue ?? $props.value,
    placeholder: $props.customPlaceholder ?? $props.label,
    class: ["email-element normal focus:outline-none rounded-t-md pr-5", {
      error: $setup.error ?? $props.errorMessage,
      warning: $props.warningMessage,
      success: $props.successMessage,
      hasPlaceholderInput: $props.customPlaceholder,
      'text-right': $props.align == 'right',
      'text-center': $props.align == 'center',
      'text-left': $props.align == 'left'
    }],
    disabled: !!$props.disabled,
    readonly: $props.readonly,
    autocomplete: $props.autocomplete
  }), null, 16, _hoisted_3), _createElementVNode("div", _hoisted_4, [_withDirectives(_createVNode(_component_BaseLoading, {
    class: _normalizeClass(["mt-0.5 mb-0 transition-all duration-300 ease-in-out", {
      'opacity-100': $setup.meta?.pending,
      'opacity-0': !$setup.meta?.pending
    }])
  }, null, 8, ["class"]), [[_vShow, $props.validateInternal && $setup.meta?.pending]])]), _createElementVNode("label", {
    class: _normalizeClass(["absolute pl-5 font-semibold text-grey-500 normal transition-all duration-300 ease-in-out", {
      error: $setup.error ?? $props.errorMessage,
      warning: $props.warningMessage,
      success: $props.successMessage
    }])
  }, [_createElementVNode("span", null, _toDisplayString($props.label ?? "Base Input"), 1)], 2), $props.required ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*")) : _createCommentVNode("", true)]), this.$slots.append ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_renderSlot(_ctx.$slots, "append", {}, undefined, true)])) : _createCommentVNode("", true)], 2), _withDirectives(_createElementVNode("div", {
    class: _normalizeClass(["text-xs font-semibold mb-1 mt-0 px-2 messages", $props.hint || $setup.error || $props.errorMessage || $props.warningMessage || $props.successMessage || _ctx.$slots.messages ? 'min-h-4' : 'h-4'])
  }, [_renderSlot(_ctx.$slots, "messages", {}, () => [_createVNode(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [$setup.error ?? $props.errorMessage ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($setup.error ?? $props.errorMessage), 1)) : $props.warningMessage ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($props.warningMessage), 1)) : $props.successMessage ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($props.successMessage), 1)) : $props.hint ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($props.hint), 1)) : _createCommentVNode("", true)]),
    _: 1
  })], true)], 2), [[_vShow, !$props.noDetails || $props.hint || $setup.error || $props.errorMessage || $props.warningMessage || $props.successMessage]])]);
}