import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9753d6a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "relative grow base-input rounded-t-md"
};
const _hoisted_2 = ["disabled", "readonly", "value", "placeholder"];
const _hoisted_3 = {
  key: 0,
  class: "absolute top-0 right-1 text-red-600 font-bold"
};
const _hoisted_4 = {
  class: "append-slot w-12 flex justify-center items-center border-b-2 border-grey-200"
};
const _hoisted_5 = {
  key: 0,
  class: "text-red-500"
};
const _hoisted_6 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CalendarIcon = _resolveComponent("CalendarIcon");
  const _component_Datepicker = _resolveComponent("Datepicker");
  return _openBlock(), _createBlock(_component_Datepicker, _mergeProps({
    value: $props.modelValue,
    "onUpdate:modelValue": $options.handleDate
  }, {
    ..._ctx.$attrs,
    ..._ctx.$props,
    disabled: !!$props.disabled
  }, {
    onOpen: _cache[2] || (_cache[2] = $event => $setup.callOpenHandler($event)),
    "auto-position": true,
    "teleport-center": ""
  }), {
    trigger: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["relative flex bg-grey-100 rounded-t-md", {
        ' shadow-md shadow-primary-400': $props.active
      }])
    }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("input", _mergeProps({
      disabled: !!$props.disabled,
      readonly: $props.readonly
    }, _ctx.$attrs, {
      value: $options.formatedDate,
      onBlur: _cache[0] || (_cache[0] = (...args) => $setup.handleBlur && $setup.handleBlur(...args)),
      onChange: _cache[1] || (_cache[1] = (...args) => $setup.handleChange && $setup.handleChange(...args)),
      class: ["input-element normal focus:outline-none rounded-t-md", {
        error: $props.errorMessage,
        warning: $props.warningMessage,
        success: $props.successMessage
      }],
      placeholder: $props.label
    }), null, 16, _hoisted_2), _createElementVNode("label", {
      class: _normalizeClass(["absolute pl-5 font-semibold text-grey-500 normal", {
        error: $props.errorMessage,
        warning: $props.warningMessage,
        success: $props.successMessage
      }]),
      for: "userName"
    }, [_createElementVNode("span", null, _toDisplayString($props.label ?? "Base Input"), 1)], 2)]), $props.required ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_CalendarIcon, {
      class: "w-5 h-5 text-grey-500"
    })])], 2), !$props.noDetails ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(["text-xs font-semibold my-1 px-2 messages", $props.hint || $setup.error || $props.errorMessage || $props.warningMessage || $props.successMessage || _ctx.$slots.messages ? 'min-h-4' : 'h-4'])
    }, [_renderSlot(_ctx.$slots, "messages", {}, () => [_createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [$setup.error ?? $props.errorMessage ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.error ?? $props.errorMessage), 1)) : $props.hint ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.hint), 1)) : _createCommentVNode("", true)]),
      _: 1
    })], true)], 2)) : _createCommentVNode("", true)]),
    _: 3
  }, 16, ["value", "onUpdate:modelValue"]);
}